export type CancellationPolicy = {
    id: CancellationPolicyId;
    displayName: string;
    fiftyPercentRefundDeadlineDays: number;
    noRefundDeadlineDays: number;
    paymentDeadlineDays: number;
    isActive: boolean;
};

export type CancellationPolicyId = 'LEGACY' | 'FLEXIBLE' | 'MODERATE' | 'STRICT';

export const createCancellationPolicies = (data: Record<string, unknown>[]): CancellationPolicy[] =>
    data.map(
        (policy): CancellationPolicy => ({
            id: policy.id as CancellationPolicyId,
            displayName: policy.displayName as string,
            fiftyPercentRefundDeadlineDays: policy.fiftyPercentRefundDeadlineDays as number,
            noRefundDeadlineDays: policy.noRefundDeadlineDays as number,
            paymentDeadlineDays: policy.paymentDeadlineDays as number,
            isActive: policy.isActive as boolean,
        }),
    );
