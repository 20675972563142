import 'isomorphic-fetch';
import getConfig from 'next/config';

import { queryString } from 'shared/util/query';
import type { Filters, ItemNullableData } from '@/domain';
import { authRequest, createFormPost, createPost, handleFailure } from '@/api/apiHelpers';
import { apiListAdImageWrapper } from '@/utils/urlPrepender';
import { type CancellationPolicy, createCancellationPolicies } from '@/models/cancellationPolicy';

const {
    publicRuntimeConfig: { contextPath, apiExternalUrl },
    serverRuntimeConfig: { apiInternalUrl, profileReadUrl },
} = getConfig();

const requestOptions: RequestInit = { credentials: 'include' };

/* FHH API fetch functions: */

export const fetchResults = (filters: Filters = {}) => {
    const { destination: _unused, ...filtersWithoutDestination } = filters;
    return fetch(`${apiExternalUrl}/list${queryString(filtersWithoutDestination)}`, requestOptions)
        .then(handleFailure)
        .then(apiListAdImageWrapper);
};

export const fetchSearchId = (filters: Filters = {}) => {
    const { destination: _unused, ...filtersWithoutDestination } = filters;
    return fetch(`${apiInternalUrl}/search`, createPost(filtersWithoutDestination)).then(handleFailure);
};

export const fetchMapResults = (filters: Filters = {}) => {
    const { destination: _unused, ...filtersWithoutDestination } = filters;
    const url =
        apiInternalUrl !== undefined
            ? `${apiInternalUrl}/map-list${queryString(filtersWithoutDestination)}`
            : `${apiExternalUrl}/map-list${queryString(filtersWithoutDestination)}`;
    return fetch(url, requestOptions).then(handleFailure);
};

export const postSaveSearch = (name, notify, filters: Filters = {}) => {
    const { destination: _unused, ...filtersWithoutDestination } = filters;
    return fetch(
        `${apiExternalUrl}/save-search/save`,
        createPost({
            savedSearchName: name,
            mailNotifications: notify,
            webNotifications: false,
            searchParams: queryString(filtersWithoutDestination, false),
        }),
    ).then(handleFailure);
};

export const fetchProfileSsr = (loginId) => fetch(`${profileReadUrl}profile/sdrn:finn:user:${loginId}`).then(handleFailure);

export const fetchAutocompleteSuggestions = (filters, sessionToken) =>
    fetch(`${apiExternalUrl}/autocomplete${queryString(filters)}`, { headers: { 'x-gtoken': sessionToken } }).then(handleFailure);

export const fetchAvailabilityForImport = (data) =>
    fetch(`${apiExternalUrl}/calendar/availabilityImport${queryString(data)}`).then(handleFailure);

export const fetchAvailabilityPerDateSsr = (data) =>
    fetch(`${apiInternalUrl}/travel-api/fhh/calendar/availability${queryString(data)}`).then(handleFailure);

export const fetchPriceForImport = (data) => fetch(`${apiExternalUrl}/calendar/price${queryString(data)}`).then(handleFailure);

export const fetchStreetNamesFromWord = (data) =>
    fetch(`${apiExternalUrl}/address/street${queryString(data)}`, { credentials: 'include' }).then(handleFailure);

export const fetchStreetNumbersFromIds = (data) =>
    fetch(`${apiExternalUrl}/address/streetNumbers${queryString(data)}`, { credentials: 'include' }).then(handleFailure);

export const fetchFloorsFromDeliveryPointId = (data) =>
    fetch(`${apiExternalUrl}/address/floors${queryString(data)}`, { credentials: 'include' }).then(handleFailure);

export const fetchHouseholds = (data) =>
    fetch(`${apiExternalUrl}/address/households${queryString(data)}`, { credentials: 'include' }).then(handleFailure);

export const fetchCalendarPriceSsr = (data) =>
    fetch(`${apiInternalUrl}/travel-api/fhh/calendar/price${queryString(data)}`).then(handleFailure);

export const fetchPriceInfoSsr = (adId, authCookies) =>
    fetch(`${apiInternalUrl}/travel-api/fhh/price/info/${adId}`, authRequest(authCookies)).then(handleFailure);

export const createOrUpdatePriceInfo = (data) => fetch(`${apiExternalUrl}/price/info/create`, createPost(data)).then(handleFailure);

export const postPriceOverrideData = (data) => fetch(`${apiExternalUrl}/price/override/create`, createPost(data)).then(handleFailure);

export const deletePriceOverride = (data) => fetch(`${apiExternalUrl}/price/override/delete`, createPost(data)).then(handleFailure);

export const fetchObjectPriceInfo = (adId) => fetch(`${apiExternalUrl}/price/summary/${adId}`).then(handleFailure);

export const updateRentalRestrictions = (data) => fetch(`${apiExternalUrl}/price/rental`, createPost(data)).then(handleFailure);

export const updateRentalRestrictionsNew = (data) => fetch(`${apiExternalUrl}/price/availability`, createPost(data)).then(handleFailure);

export const getAllCancellationPolicies = async (itemId): Promise<CancellationPolicy[]> =>
    fetch(`${apiExternalUrl}/cancellation-policy/${itemId}/all`).then(handleFailure).then(createCancellationPolicies);

export const postOrderData = (data) => fetch(`${apiExternalUrl}/calendar/order`, createFormPost(data)).then(handleFailure);

export const postItemInsertion = (itemId, data) =>
    fetch(`${apiExternalUrl}/item/${itemId}/insertion`, createPost(data)).then(handleFailure);

export const fetchItemInsertionSsr = (itemId, authCookies): Promise<ItemNullableData> =>
    fetch(`${apiInternalUrl}/travel-api/fhh/item/${itemId}/insertion`, authRequest(authCookies)).then(handleFailure);

export const fetchBookingDataSsr = (adId, authCookies) =>
    fetch(`${apiBase()}/booking/list/${adId}`, authRequest(authCookies)).then(handleFailure);

export const postBookingData = (action, data) =>
    fetch(`${apiExternalUrl}/booking/${action}/${data.adId}`, createPost(data)).then(handleFailure);

export const fetchObjectRecommendationAds = (adId) =>
    fetch(`${contextPath}/api/mlt/travel-fhh/${adId}?rows=6`, requestOptions).then(handleFailure);

export const fetchPermissionSsr = (adId: string | number, authCookies?: string): Promise<boolean> =>
    fetch(`${apiInternalUrl}/permission/${adId}`, authRequest(authCookies))
        .then(handleFailure)
        .catch(() => false); // Returns 401 if user lacks permission

export const fetchUserDataSsr = (encryptedUserId: string, authCookies?: string) =>
    fetch(`${apiInternalUrl}/travel-api/fhh/user/validate/${encryptedUserId}`, authRequest(authCookies)).then(handleFailure);

export const trackFusClick = (adId) => fetch(`${apiExternalUrl}/fus/clicked`, createPost({ adId })).then(handleFailure);

const apiBase = () => {
    if (typeof window === 'undefined') {
        // if (false) {
        return `${apiInternalUrl}/travel-api/fhh`;
    }
    return apiExternalUrl;
};
